var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-steps',{attrs:{"current":_vm.current}},_vm._l((_vm.steps),function(item){return _c('a-step',{key:item.title,attrs:{"title":item.title}})}),1),_c('div',{staticClass:"steps-content"},[(_vm.current==0)?_c('div',[_c('a-card',{staticClass:"my-5"},[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical","hide-required-mark":false},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"First Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'first_name',
                {
                  rules: [
                    { required: true, message: 'Please enter user name' } ],
                } ]),expression:"[\n                'first_name',\n                {\n                  rules: [\n                    { required: true, message: 'Please enter user name' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Please enter user name"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Last Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'last_name',
                {
                  rules: [
                    {
                      required: true,
                      message: 'please enter your last name',
                    } ],
                } ]),expression:"[\n                'last_name',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'please enter your last name',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"last name"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'email',
                {
                  rules: [{ required: true, message: 'please enter email' }],
                } ]),expression:"[\n                'email',\n                {\n                  rules: [{ required: true, message: 'please enter email' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"email"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Phone Number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'phone_number',
                {
                  rules: [{ required: true, message: 'please enter phone' }],
                } ]),expression:"[\n                'phone_number',\n                {\n                  rules: [{ required: true, message: 'please enter phone' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"phone"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"National ID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'national_id',
                {
                  rules: [{ required: true, message: 'Field is required' }],
                } ]),expression:"[\n                'national_id',\n                {\n                  rules: [{ required: true, message: 'Field is required' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"national_id"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"KRA PIN"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'kra_pin',
                {
                  rules: [{ required: true, message: 'Field is required' }],
                } ]),expression:"[\n                'kra_pin',\n                {\n                  rules: [{ required: true, message: 'Field is required' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"KRA PIN"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"NSSF NUMBER"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'nssf_number',
                {
                  rules: [{ required: true, message: 'Field is required' }],
                } ]),expression:"[\n                'nssf_number',\n                {\n                  rules: [{ required: true, message: 'Field is required' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"nssf number"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"NHIF NUMBER"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'nhif_number',
                {
                  rules: [{ required: true, message: 'Field is required' }],
                } ]),expression:"[\n                'nhif_number',\n                {\n                  rules: [{ required: true, message: 'Field is required' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"NHIF"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Department"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'department',
                {
                  rules: [
                    { required: true, message: 'Please select a department' } ],
                } ]),expression:"[\n                'department',\n                {\n                  rules: [\n                    { required: true, message: 'Please select a department' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"department"}},_vm._l((_vm.currentClient.departments),function(department){return _c('a-select-option',{key:department.department_name,attrs:{"value":department.department_name}},[_vm._v(" "+_vm._s(department.department_name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Designation"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'designation',
                {
                  rules: [
                    { required: true, message: 'please enter designation' } ],
                } ]),expression:"[\n                'designation',\n                {\n                  rules: [\n                    { required: true, message: 'please enter designation' },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"designation"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Bank Name"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'bank_name',
                {
                  rules: [
                    { required: true, message: 'Please choose the bank' } ],
                } ]),expression:"[\n                'bank_name',\n                {\n                  rules: [\n                    { required: true, message: 'Please choose the bank' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Please choose the bank"}},[_c('a-select-option',{attrs:{"value":"equity"}},[_vm._v(" Equity Bank ")]),_c('a-select-option',{attrs:{"value":"kcb"}},[_vm._v(" KCB Bank ")])],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Branch Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'bank_branch',
                {
                  rules: [{ required: true, message: 'please enter branch' }],
                } ]),expression:"[\n                'bank_branch',\n                {\n                  rules: [{ required: true, message: 'please enter branch' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Branch Name"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Account Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'account_name',
                {
                  rules: [
                    { required: true, message: 'please enter account' } ],
                } ]),expression:"[\n                'account_name',\n                {\n                  rules: [\n                    { required: true, message: 'please enter account' },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"account Name"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Account Number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'account_number',
                {
                  rules: [
                    {
                      required: true,
                      message: 'please enter account number',
                    } ],
                } ]),expression:"[\n                'account_number',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'please enter account number',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"account number","type":"number"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Deductions"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'deductions',
                {
                  rules: [
                    { required: true, message: 'Please choose the bank' } ],
                } ]),expression:"[\n                'deductions',\n                {\n                  rules: [\n                    { required: true, message: 'Please choose the bank' },\n                  ],\n                },\n              ]"}],attrs:{"mode":"multiple","placeholder":"Please choose the bank"}},[_c('a-select-option',{attrs:{"value":"equity"}},[_vm._v(" NHIF ")]),_c('a-select-option',{attrs:{"value":"kcb"}},[_vm._v(" NSSF")]),_c('a-select-option',{attrs:{"value":"sacco"}},[_vm._v(" Saccos")])],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Basic Pay"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'basic_pay',
                {
                  rules: [
                    { required: true, message: 'please enter account' } ],
                } ]),expression:"[\n                'basic_pay',\n                {\n                  rules: [\n                    { required: true, message: 'please enter account' },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Basic Pay"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Allowances"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'allowances',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Please choose the allowances',
                    } ],
                } ]),expression:"[\n                'allowances',\n                {\n                  rules: [\n                    {\n                      required: false,\n                      message: 'Please choose the allowances',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"mode":"multiple","placeholder":"Please choose allowances"}},[_c('a-select-option',{attrs:{"value":"housing"}},[_vm._v(" Housing ")]),_c('a-select-option',{attrs:{"value":"food"}},[_vm._v(" Food")]),_c('a-select-option',{attrs:{"value":"tranport"}},[_vm._v(" Transport")])],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Status"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'status',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please select employee status',
                    } ],
                } ]),expression:"[\n                'status',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select employee status',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Status"}},[_c('a-select-option',{attrs:{"value":"active"}},[_vm._v(" Active ")]),_c('a-select-option',{attrs:{"value":"inactive"}},[_vm._v("Inactive")])],1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Gender"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'gender',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please select',
                    } ],
                } ]),expression:"[\n                'gender',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select',\n                    },\n                  ],\n                },\n              ]"}]},[_c('a-select-option',{attrs:{"value":"male"}},[_vm._v(" Male ")]),_c('a-select-option',{attrs:{"value":"female"}},[_vm._v("Female")])],1)],1)],1)],1)],1)],1)],1):_vm._e()]),_c('div',{staticClass:"steps-action"},[(_vm.current < _vm.steps.length - 1)?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.next}},[_vm._v(" Next ")]):_vm._e(),(_vm.current == _vm.steps.length - 1)?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$message.success('Processing complete!')}}},[_vm._v(" Done ")]):_vm._e(),(_vm.current > 0)?_c('a-button',{staticStyle:{"margin-left":"8px"},on:{"click":_vm.prev}},[_vm._v(" Previous ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }